import { Link } from "react-router-dom"

const Footer = () => {
	return (
		<footer className="bg-grayLight py-8">
			<ul className="md:text-center ">
				<li className="md:inline-block px-6 pb-2">
					<Link to="/impressum" target="_blank" className="text-sm underline">
						Impressum
					</Link>
				</li>
				<li className="md:inline-block px-6 pb-2">
					<Link to="/datenschutz" target="_blank" className="text-sm underline">
						Datenschutzerklärung
					</Link>
				</li>
				<li className="md:inline-block px-6 text-sm">
					Bei Fragen wende dich gerne per E-Mail an unser Service-Team:{" "}
					<a href="mailto:feedback@kurkliniksuche.de" className="underline">
						feedback@kurkliniksuche.de
					</a>
				</li>
			</ul>
		</footer>
	)
}

export default Footer
