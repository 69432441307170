import { FaRegStar, FaRegStarHalfStroke, FaStar } from "react-icons/fa6"

// Hilfsfunktion, um jeden Teil eines Strings entsprechend zu formatieren
export function formatClinicNamePart(part) {
	// Liste von Wörtern, die klein geschrieben bleiben sollen
	const smallWords = ["am", "im", "der", "die", "das", "für", "und", "von", "bei", "mit", "zu", "an", "auf", "in", "aus"]

	// Konvertiere das Wort zu Kleinbuchstaben
	const lowerCasePart = part.toLowerCase()

	// Prüfe, ob es ein kleines Wort ist
	if (smallWords.includes(lowerCasePart)) {
		return lowerCasePart
	}

	// Andernfalls den ersten Buchstaben groß schreiben
	return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
}

// Hauptfunktion zur Umwandlung des Strings
export function decodeAndFormatClinicName(encodedName) {
	// URL-Dekodierung des Strings
	const decodedName = decodeURIComponent(encodedName)

	// Zerlege den String bei Bindestrichen
	const parts = decodedName.split("-")

	// Wende die Formatierung auf jeden Teil an
	const formattedParts = parts.map(formatClinicNamePart)

	// Setze die Teile mit Leerzeichen zusammen
	return formattedParts.join(" ")
}

// Funktion, die Öffnungszeiten im JSON-Format in ein lesbares Format umwandelt
export function formatOpeningHours(json) {
	const days = ["So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa."]

	// Prüfen, ob "weekday_text" vorhanden ist und verwendet werden soll
	if (json.weekday_text && json.weekday_text.length > 0) {
		return json.weekday_text.map(text => {
			// Textübersetzung von Englisch auf Deutsch, wenn notwendig
			return <li>{translateWeekdayText(text)}</li>
		})
	}

	// Prüfen, ob "periods" leer oder "always open" ist
	if (json.periods && json.periods.length > 0) {
		return json.periods.map(period => {
			const openDay = days[period.open.day]
			const closeDay = period.close ? days[period.close.day] : null
			const openTime = `${period.open.time.slice(0, 2)}:${period.open.time.slice(2)}`
			const closeTime = period.close ? `${period.close.time.slice(0, 2)}:${period.close.time.slice(2)}` : null

			if (!closeTime) {
				return <li>{`${openDay}: 24 Stunden geöffnet`}</li>
			}

			if (openDay === closeDay) {
				return <li>{`${openDay}: ${openTime} - ${closeTime} Uhr`}</li>
			} else {
				return <li>{`${openDay} ${openTime} - ${closeDay} ${closeTime} Uhr`}</li>
			}
		})
	}

	// Fallback, falls keine Informationen vorliegen
	return <li>{"Keine Öffnungszeiten verfügbar"}</li>
}

// Hilfsfunktion zur Übersetzung von weekday_text
function translateWeekdayText(text) {
	const translations = {
		Monday: "Montag",
		Tuesday: "Dienstag",
		Wednesday: "Mittwoch",
		Thursday: "Donnerstag",
		Friday: "Freitag",
		Saturday: "Samstag",
		Sunday: "Sonntag",
		"Open 24 hours": "24 Stunden geöffnet",
		AM: "Uhr",
		PM: "Uhr",
	}

	let translatedText = text
	for (const [key, value] of Object.entries(translations)) {
		translatedText = translatedText.replace(new RegExp(key, "g"), value)
	}

	// Zeitformat anpassen: "7:00 AM" -> "7:00 Uhr"
	translatedText = translatedText.replace(/\b(\d{1,2}):(\d{2})\s*(AM|PM)\b/g, (match, hour, minute) => {
		return `${hour}:${minute} Uhr`
	})

	return translatedText
}

// Funktion, die prüft, ob das Geschäft gerade offen ist
export function isOpenNow(json) {
	const now = new Date()
	const currentDay = now.getDay()
	const currentTime = `${now
		.getHours()
		.toString()
		.padStart(2, "0")}${now
		.getMinutes()
		.toString()
		.padStart(2, "0")}`

	// Prüfen, ob das Geschäft immer geöffnet ist
	if (json.periods.length === 1 && json.periods[0].open && !json.periods[0].close) {
		const openDay = json.periods[0]?.open?.day
		const openTime = json.periods[0]?.open?.time

		if (openDay === 0 && openTime === "0000") {
			return true // Immer geöffnet
		}
	}

	// Prüfen, ob der aktuelle Zeitpunkt innerhalb der Öffnungszeiten liegt
	return json.periods.some(period => {
		const openDay = period.open?.day
		const closeDay = period.close?.day
		const openTime = period.open?.time
		const closeTime = period.close?.time

		if (openDay === undefined || openTime === undefined) {
			return false // Ungültiger Eintrag überspringen
		}

		if (openDay === closeDay && currentDay === openDay) {
			return currentTime >= openTime && currentTime < closeTime
		} else if (openDay !== closeDay) {
			if (currentDay === openDay && currentTime >= openTime) {
				return true
			}
			if (currentDay === closeDay && currentTime < closeTime) {
				return true
			}
		}
		return false
	})
}

export function getRatingStars(rating: number): JSX.Element[] {
	const stars: JSX.Element[] = []

	for (let i = 1; i <= 5; i++) {
		if (rating >= i) {
			// Volle Sterne
			stars.push(<FaStar className="w-6 h-6" key={i} />)
		} else if (rating >= i - 0.5) {
			// Halbe Sterne
			stars.push(<FaRegStarHalfStroke className="w-6 h-6" key={i} />)
		} else {
			// Leere Sterne
			stars.push(<FaRegStar className="w-6 h-6" key={i} />)
		}
	}

	return stars
}

/**
 *
 */
export const scrollToErrorField = () => {
	setTimeout(() => {
		let errField = document.getElementsByClassName("js-formerror")
		if (errField.length > 0) {
			const firstErrorField: any = errField[0]
			window.scrollTo({
				top: window.pageYOffset + firstErrorField.getBoundingClientRect().top - 200,
				left: 0,
				behavior: "smooth",
				// top: firstErrorField.offsetTop, left: 0, behavior: 'smooth'
			})
		}
	}, 50)
}

export function getDomainFromUrl(url: string): string | null {
	try {
		// URL-Objekt erstellen, um die URL zu parsen
		const parsedUrl = new URL(url)
		// Die Host-Eigenschaft enthält die Domain
		return parsedUrl.hostname
	} catch (error) {
		console.error("Ungültige URL:", error)
		return null // Falls die URL ungültig ist
	}
}
