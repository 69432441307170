import React, { createContext, useReducer } from "react"
import Reducer from "./reducer"

const initialState = {
	selectedName: null,

	azSession: null,
	showThankyouPage: false,
	code1: process.env.NODE_ENV === "development" ? "XXXXXXXXXX".toLowerCase() : null,
	code2: process.env.NODE_ENV === "development" ? "YYYYYYYYYY".toLowerCase() : null,

	strapi: {
		homepage: null,
		faq: null,
	},
	jars: [],
	botschaftKey: "a",

	rewardsStatus: null,
	weeklyOrdersLeft: null,
}

const Store = ({ children }) => {
	const [globalState, dispatch] = useReducer(Reducer, initialState)
	return <Context.Provider value={{ globalState, dispatch }}>{children}</Context.Provider>
}
export const Context = createContext<{
	globalState
	dispatch: React.Dispatch<any>
}>({
	globalState: initialState,
	dispatch: () => {},
})
export default Store
