import React from "react"
import { Link } from "react-router-dom"
import Footer from "../components/Footer"

function Privacy() {
	return (
		<div>
			<Link to={"/"}>
				<img src={process.env.PUBLIC_URL + "/images/klinikhero.jpg"} alt="Kur Klinik Suche" className=" pt-0 px-0 w-full" />
			</Link>

			<section className="contentPage max-w-[700px] mx-auto px-4 pb-12">
				<div>
					<h1>Datenschutz­erklärung</h1>
					<h2>1. Datenschutz auf einen Blick</h2>
					<h3>Allgemeine Hinweise</h3>
					<p>
						Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten
						sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
						aufgeführten Datenschutzerklärung.
					</p>
					<h3>Datenerfassung auf dieser Website</h3>
					<h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
					<p>
						Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in
						dieser Datenschutzerklärung entnehmen.
					</p>
					<h4>Wie erfassen wir Ihre Daten?</h4>
					<p>
						Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
					</p>
					<p>
						Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.&nbsp;B.
						Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
					</p>
					<h4>Wofür nutzen wir Ihre Daten?</h4>
					<p>
						Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet
						werden.
					</p>
					<h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
					<p>
						Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein
						Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit
						für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des
						Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
					</p>
					<p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.</p>
					<h2>2. Hosting</h2>
					<p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
					<h3>Netlify</h3>
					<p>
						Unsere Website wird durch den Webhosting-Anbieter Netlify unterstützt. Netlify Inc., ein Unternehmen mit Sitz in den USA (2325 3rd Street, Suite 29, San Francisco, CA
						94104, USA), ist unser Dienstleister.
					</p>
					<p>
						Netlify verarbeitet Daten, einschließlich solcher aus den USA, und ist ein aktiver Teilnehmer am EU-US-Datenschutzrahmen. Dieser Rahmen regelt den sicheren Transfer
						personenbezogener Daten von EU-Bürgern in die USA. Weitere Informationen finden Sie unter:
						https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en.
					</p>
					<p>
						Zusätzlich nutzt Netlify Standardvertragsklauseln gemäß Art. 46 Abs. 2 und 3 der DSGVO, um sicherzustellen, dass Ihre Daten die europäischen Datenschutzstandards
						einhalten, auch wenn sie in Drittländer wie die USA übertragen und dort gespeichert werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission.
						Weitere Details und die Standardvertragsklauseln finden Sie hier: https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de
					</p>
					<p>
						Netlify stellt einen Auftragsverarbeitungsvertrag gemäß Art. 28 DSGVO bereit, der als Grundlage für unsere Kundenbeziehung dient. Dieser Vertrag bezieht sich auf die
						EU-Standardvertragsklauseln. Den Vertrag finden Sie hier: https://www.netlify.com/pdf/netlify-dpa.pdf.
					</p>
					<p>Für weitere Informationen über die von Netlify verarbeiteten Daten lesen Sie bitte die Datenschutzerklärung unter: https://www.netlify.com/privacy/.</p>
					<h2>3. Allgemeine Hinweise und Pflicht­informationen</h2>
					<h3>Datenschutz</h3>
					<p>
						Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den
						gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
					</p>
					<p>
						Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden
						können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
					</p>
					<p>
						Wir weisen darauf hin, dass die Datenübertragung im Internet (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der
						Daten vor dem Zugriff durch Dritte ist nicht möglich.
					</p>
					<h3>Hinweis zur verantwortlichen Stelle</h3>
					<p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
					<p>Björn Rühle</p>
					<p>Maurepasstr. 99h</p>
					<p>24558 Henstedt-Ulzburg</p>
					<p>Deutschland</p>
					<p>Tel.: 01636295035</p>
					<p>
						Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
						personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
					</p>
					<h3>Speicherdauer</h3>
					<p>
						Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die
						Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht,
						sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z.&nbsp;B. steuer- oder handelsrechtliche
						Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
					</p>
					<h3>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h3>
					<p>
						Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2
						lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung
						personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies
						oder in den Zugriff auf Informationen in Ihr Endgerät (z.&nbsp;B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage
						von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich,
						verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen
						Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art.
						6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.
					</p>
					<h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
					<p>
						Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die
						Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
					</p>
					<h3>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</h3>
					<p>
						WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
						SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE
						JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
						PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND
						FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
					</p>
					<p>
						WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
						PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
						WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
					</p>
					<h3>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h3>
					<p>
						Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen
						Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
						gerichtlicher Rechtsbehelfe.
					</p>
					<h3>Recht auf Daten­übertrag­barkeit</h3>
					<p>
						Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem
						gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
						soweit es technisch machbar ist.
					</p>
					<h3>Auskunft, Berichtigung und Löschung</h3>
					<p>
						Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren
						Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
						personenbezogene Daten können Sie sich jederzeit an uns wenden.
					</p>
					<h3>Recht auf Einschränkung der Verarbeitung</h3>
					<p>
						Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf
						Einschränkung der Verarbeitung besteht in folgenden Fällen:
					</p>
					<ul>
						<li>
							Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der
							Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
						</li>
						<li>
							Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
						</li>
						<li>
							Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie
							das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
						</li>
						<li>
							Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht
							feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
						</li>
					</ul>
					<p>
						Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur
						Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines
						wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
					</p>
					<h3>SSL- bzw. TLS-Verschlüsselung</h3>
					<p>
						Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als
						Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf
						„https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
					</p>
					<p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>
					<h3>Google Analytics 4</h3>
					<p>
						Wir verwenden im Rahmen unseres berechtigten Interesses an einem technisch einwandfreien Online Angebot und seiner wirtschaftlich-effizienten Gestaltung und Optimierung
						gem. Art.6 Abs. 1 lit.f DSGVO sowie Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO das Tool Google Analytics von Google Ireland Limited, Gordon House, Barrow
						Street, Dublin 4, Irland.
					</p>
					<p>
						Damit kann die Nutzung von Webseiten analysiert werden, dabei können aus den Daten pseudonyme Profile der Nutzer erstellt werden. Dazu werden von Google verschiedene
						Techniken angewandt, die auf der Anwendung von Algorithmen basieren. Insbesondere werden dafür folgende Daten erhoben: Pageviews, Scrolls bis zum Seitenende, Klicks auf
						externe Links, Website-Suchen, Ansehen eines eingebetteten YouTube – Videos, Dateidownloads.
					</p>
					<p>
						Die von Google erhobenen Daten könnten von Google in Staaten außerhalb der EU, insbesondere die USA, übermittelt werden. Wir haben mit Google einen
						Auftragsverarbeitungsvertrag (AVV) geschlossen, wonach Google Ihre Daten nur nach unserer Weisung verarbeitet. Dieser AVV sieht zudem vor, dass die Übertragung der
						Daten in die USA durch Google nur im Rahmen der Standardvertragsklauseln erfolgt, vgl. dazu die Auftragsdatenverarbeitungsbedingungen für Google Werbeprodukte, abrufbar
						unter https://business.safety.google/adsprocessorterms/.
					</p>
					<p>
						Google anonymisiert Ihre IP-Adresse in einem technischen Verfahren automatisch, sodass sie dort nicht auf einer Festplatte gespeichert wird. Um einen angemessenen
						Schutz Ihrer Daten sicherzustellen, haben wir weiterhin mit Google einen Vertrag zur Auftragsdatenverarbeitung geschlossen, wonach Google Ihre Daten nicht mit anderen
						von Google erhobenen Daten zusammenführen wird, um Ihre Identität zu ermitteln.
					</p>
					<p>
						Sofern Ihnen dies nicht ausreicht, können Sie darüber hinaus unter dem Link http://tools.google.com/dlpage/gaoptout?hl=de das Browser-Plugin von Google zur Sperrung von
						Google Analytics herunterladen und installieren, womit die Erfassung und Weitergabe ihrer personenbezogenen Daten durch Google blockiert wird.
					</p>
					<p>
						Ebenso können Sie die Erfassung durch Google Analytics verhindern, indem Sie durch Betätigung des nachfolgenden Links: Google Analytics deaktivieren einen
						Opt-Out-Cookie setzen, der ebenfalls in die Erfassung ihrer Daten verhindert.
					</p>
					<p>
						Wenn Sie sich über die Vorkehrungen von Google zum Datenschutz informieren möchten, benutzen Sie bitte den folgenden Link
						https://www.google.de/intl/de/policies/privacy/.
					</p>
					<p>
						Alternativ zum Browser-Add-On oder innerhalb von Browsern auf mobilen Geräten, klicken Sie bitte diesen Link, um die Erfassung durch Google Analytics innerhalb dieser
						Website zukünftig zu verhindern. Dabei wird ein Opt-Out-Cookie auf Ihrem Gerät abgelegt. Löschen Sie Ihre Cookies, müssen Sie diesen Link erneut klicken.
					</p>
					<p>
						Weitere Informationen zur Datennutzung zu Werbezwecken durch Google, Einstellungs- und Widerspruchsmöglichkeiten erfahren Sie auf den Webseiten von Google:
						https://www.google.com/intl/de/policies/privacy/partners/ („Datennutzung durch Google bei Ihrer Nutzung von Websites oder Apps unserer Partner“),
						http://www.google.com/policies/technologies/ads („Datennutzung zu Werbezwecken“), http://www.google.de/settings/ads („Informationen verwalten, die Google verwendet, um
						Ihnen Werbung einzublenden“) und http://www.google.com/ads/preferences/ („Bestimmen Sie, welche Werbung Google Ihnen zeigt“).
					</p>
					<h3>Datenschutzerklärung für die Nutzung von Google AdSense</h3>
					<p>
						Unsere Website benutzt Google AdSense, einen Dienst zum Einbinden von Werbeanzeigen der Google Inc. (“Google”). Für diesen Dienst zum Einbinden von Werbeanzeigen
						verwendet Google AdSense so genannte “Cookies”, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website ermöglicht.
						Darüber hinaus verwendet Google AdSense auch Web Beacons (unsichtbare Grafiken). Aufgrund von den Web Beacons können Informationen wie der Besucherverkehr auf Websiten
						ausgewertet werden. Anhand der Cookies und den Web Beacons werden Informationen über die Benutzung unserer Website, inbegriffen Ihrer IP-Adresse, erzeugt. Die
						Auslieferung von Werbeformaten wird dann an einen Server von Google in den USA übertragen und dort, wie bei Google Analytics, gespeichert. Die gespeicherten
						Informationen können dann von Google weiter an deren Vertragspartner gegeben werden. Dennoch werden die von Google erhobenen Informationen über Ihre IP-Adresse nicht
						mit anderen von Ihnen gespeicherten Daten zusammen geführt Liegt diese Erfassung der Informationen anhand Ihres Aufenthaltes auf unserer Seite nicht in Ihrem Interesse,
						so können sie die Speicherung oder Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern. Gleichzeitig möchten wir Sie in
						Kenntnis darüber setzen, dass Sie in diesem Fall sämtliche Funktionen dieser Website nicht vollumfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie
						sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
					</p>
					<h3>Cookies:</h3>
					<table>
						<tbody>
							<tr>
								<td>Cookie</td>
								<td>Beschreibung</td>
								<td>Ablauf</td>
								<td>Typ</td>
							</tr>
							<tr>
								<td>_ga_#</td>
								<td>Sammelt Daten dazu, wie oft ein Benutzer eine Website besucht hat, sowie Daten für den ersten und letzten Besuch. Von Google Analytics verwendet.</td>
								<td>2 Jahre</td>
								<td>HTTP</td>
							</tr>
							<tr>
								<td>__gpi</td>
								<td>
									Sammelt Informationen zum Besucherverhalten auf mehreren Webseiten. Diese Informationen wird auf der Webseite verwendet, um die Relevanz der Werbung zu
									optimieren.
								</td>
								<td>1 Jahr</td>
								<td>HTTP</td>
							</tr>
							<tr>
								<td>_ga</td>
								<td>
									Cookie ist eine eindeutige Kennung für eine spezifische Property und kann daher nicht zur Nachverfolgung eines bestimmten Nutzers oder Browsers über voneinander
									unabhängige Websites hinweg verwendet werden.
								</td>
								<td>1 Jahr</td>
								<td>HTTP</td>
							</tr>
							<tr>
								<td>__gads</td>
								<td>Verwendet, um zu registrieren, welche Anzeigen dem Benutzer angezeigt werden.</td>
								<td>1 Jahr</td>
								<td>HTTP</td>
							</tr>
							<tr>
								<td>rcl_marketing_consent</td>
								<td>Speichere, wenn die Zustimmung für marketing gegeben wurde.</td>
								<td>1 Jahr</td>
								<td>HTTP</td>
							</tr>
							<tr>
								<td>rcl_statistics_consent</td>
								<td>Speichere, wenn die Zustimmung für statistics gegeben wurde.</td>
								<td>1 Jahr</td>
								<td>HTTP</td>
							</tr>
							<tr>
								<td>rcl_preferences_consent</td>
								<td>Speichere, wenn die Zustimmung für preferences gegeben wurde.</td>
								<td>1 Jahr</td>
								<td>HTTP</td>
							</tr>
							<tr>
								<td>rcl_consent_given</td>
								<td>Speichere, wenn der Cookiebanner angezeigt wurde.</td>
								<td>1 Jahr</td>
								<td>HTTP</td>
							</tr>
						</tbody>
					</table>
					<p>Quelle: https://www.e-recht24.de</p>
				</div>
			</section>
			<Footer />
		</div>
	)
}

export default Privacy
