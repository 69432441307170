import clsx from "clsx"

interface ElemInputProps {
	children?: any
	hasError?: any
	errMsg?: any
	leftCount?: any
	label?: any
	required?: any
	value?: any
	labelOnTop?: boolean
}

const ElemInput: React.FC<ElemInputProps> = ({ label, value, required, hasError, errMsg, leftCount, labelOnTop, children }) => {
	return (
		<label className="relative block">
			{children}

			<span
				className={clsx(
					" peer-focus:-translate-y-4 peer-focus:text-[12px] peer-focus:text-black px-4 transition absolute left-0 top-0  h-[60px] leading-[60px] whitespace-nowrap pointer-events-none font-Montserrat",
					!!value || labelOnTop ? "-translate-y-4 text-[12px] text-black" : " text-[16px] text-black/70"
				)}
			>
				{label} {required && "*"}
			</span>

			{leftCount && (
				<div className="font-Montserrat font-bold absolute top-[17px] right-10 text-black">
					{value.length | 0}/{leftCount}
				</div>
			)}

			{hasError && <div className="formErrorMsg">{hasError.message || errMsg}</div>}
		</label>
	)
}

export default ElemInput
