import clsx from "clsx"
import { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { Context } from "../store/store"
import AutoAnimate from "./AutoAnimate"
import Spinner from "./elements/Spinner"
import ElemInput from "./elements/Input"
import axios from "axios"

function SearchForm() {
	const { globalState, dispatch } = useContext(Context)
	const [loading, setLoading] = useState<boolean>(false)
	const [zipCode, setZipCodeData] = useState<any>(null)

	const {
		register,
		handleSubmit,
		setError,
		setValue,
		watch,
		clearErrors,
		formState: { errors },
	} = useForm()

	async function handleFormSubmit(data) {
		setLoading(true)
		clearErrors()
		axios
			.get((process.env.NODE_ENV === "development" ? "" : "/api") + "/getZipCodeData.php?key=" + data.zipCode)
			.then(res => {
				if (res.data.values) {
					setZipCodeData(res.data.values[0])
				} else {
					setError("zipCode", { type: "custom", message: "Rückgabe umzulässig." })
				}
				setLoading(false)
			})
			.catch(err => {
				setError("zipCode", { type: "custom", message: err.response.data.error })
				setLoading(false)
			})
	}

	return (
		<div className="px-4">
			<form noValidate autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
				<div className="mx-auto max-w-[600px]">
					<AutoAnimate>
						{loading && (
							<div className="text-center mt-6 -mb-4">
								<Spinner />
							</div>
						)}
					</AutoAnimate>

					<div className="grid grid-cols-[1fr_150px] items-top">
						<ElemInput label="Deine Postleitzahl" hasError={errors.zipCode} errMsg={"Bitte eine gültige Postleitzahl eingeben."} required={true} value={watch("zipCode", false)}>
							<input
								{...register("zipCode", { required: true, maxLength: 5, minLength: 5 })}
								type="text"
								minLength={5}
								maxLength={5}
								required
								className={clsx("peer inputElem ", errors.zipCode && "inputElemErr js-formerror")}
							/>
						</ElemInput>
						<div>
							<button className="btn w-full" type="submit" disabled={loading ? true : undefined}>
								suchen
							</button>
						</div>
					</div>

					<AutoAnimate>
						{zipCode && (
							<>
								<h2>Welches Fachgebiet suchen Sie im Umkreis von {zipCode.ort}?</h2>
							</>
						)}
					</AutoAnimate>
				</div>
			</form>
		</div>
	)
}

export default SearchForm
