import { BrowserRouter, Route, Routes } from "react-router-dom"
import Error from "./nav/Error"
import Home from "./nav/Home"
import { CookieBanner } from "@keepist/react-gdpr-cookie-banner"
import Store from "./store/store"
import Klinik from "./nav/Klinik"
import Imprint from "./nav/Imprint"
import Privacy from "./nav/Privacy"
import Search from "./nav/Search"

const App = () => {
	return (
		<BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
			<Store>
				<main>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/k/:klinikId" element={<Klinik />} />
						<Route path="/suchen" element={<Search />} />
						<Route path="/impressum" element={<Imprint />} />
						<Route path="/datenschutz" element={<Privacy />} />
						<Route path="/fehler" element={<Error />} />
					</Routes>
				</main>
			</Store>

			<CookieBanner
				message="Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben."
				showPreferencesOption={false}
				policyLink={"/datenschutz"}
				privacyPolicyLinkText={"Datenschutzerklärung"}
				necessaryOptionText={"Notwendige"}
				statisticsOptionText={"Statistik"}
				marketingOptionText={"Marketing"}
				acceptAllButtonText={"alle Cookies akzeptieren"}
				acceptSelectionButtonText={"ausgewählte akzeptieren"}
				showAcceptSelectionButton={true}
				statisticsOptionInitiallyChecked={true}
				marketingOptionInitiallyChecked={true}
				onAcceptStatistics={() => {
					var script = document.createElement("script")
					script.type = "text/javascript"
					script.src = "https://www.googletagmanager.com/gtag/js?id=G-796MVCKLKZ"
					script.setAttribute("async", "")
					document.querySelector("head")?.appendChild(script)

					var script2 = document.createElement("script")
					script2.type = "text/javascript"
					script2.innerText = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-796MVCKLKZ');"
					document.querySelector("head")?.appendChild(script2)
				}}
				onAcceptMarketing={() => {
					var script = document.createElement("script")
					script.type = "text/javascript"
					script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7474807493757526"
					script.setAttribute("crossorigin", "anonymous")
					script.setAttribute("async", "")
					document.querySelector("head")?.appendChild(script)
				}}
				styles={{
					dialog: {
						backgroundColor: "rgba(0,0,0,0.5)",
						position: "fixed",
						top: "0",
						left: "0",
						width: "100%",
						height: "100%",
						zIndex: "100",
						padding: "1rem",
					},
					container: {
						backgroundColor: "white",
						padding: "1rem",
						position: "absolute",
						maxWidth: "600px",
						top: "50%",
						left: "50%",
						width: "calc(100% - 2rem)",
						borderRadius: "15px",
						border: "2px solid #000",

						transform: "translate(-50%, -50%)",
					},
					buttonWrapper: {
						display: "grid",
						gap: "1rem",
						gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
						marginTop: "1rem",
					},
					button: {
						background: "#FFF",
						padding: "0.5rem",
						color: "#041124",
						borderRadius: ".3rem",
						border: "2px solid black",
						width: "100%",
					},
				}}
			/>
		</BrowserRouter>
	)
}

export default App
