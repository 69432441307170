import React, { useState, useEffect } from "react"
import Alert from "./elements/Alert"

const Notiz = ({ id }) => {
	const [note, setNote] = useState<string>("")

	// Funktion, um den Schlüssel im Local Storage zu generieren
	const getStorageKey = (id: string) => `note-${id}`

	useEffect(() => {
		if (id) {
			// Vorbefüllen der Notiz aus dem Local Storage
			const storedNote = localStorage.getItem(getStorageKey(id))
			if (storedNote) {
				setNote(storedNote)
			}
		}
	}, [id])

	const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const newNote = event.target.value
		setNote(newNote)

		if (id) {
			// Speichern der Notiz im Local Storage
			localStorage.setItem(getStorageKey(id), newNote)
		}
	}

	if (!id) {
		return <Alert>Fehler: Notizen können nicht angelegt werden.</Alert>
	}

	return <textarea value={note} onChange={handleNoteChange} placeholder="Notiz hier eingeben..." style={{ width: "100%", height: "200px" }} />
}

export default Notiz
