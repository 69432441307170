import React, { useEffect } from "react"
import Footer from "../components/Footer"

const Error: React.FC = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<section className="headerAlignment  text-center  ">
			<h2 className="h3 pt-20">Hier ist etwas schief gelaufen...</h2>
			<a href="/" className="inline-block btn   mt-8">
				Zurück zur Aktion
			</a>
			<Footer />
		</section>
	)
}
export default Error
