import React, { useEffect, useState } from "react"
import { CiBookmarkCheck } from "react-icons/ci"
import { Link, useParams } from "react-router-dom"
import Footer from "../components/Footer"
import axios from "axios"
import Spinner from "../components/elements/Spinner"
import Alert from "../components/elements/Alert"
import Slider from "react-slick"
import { decodeAndFormatClinicName, formatOpeningHours, getDomainFromUrl, getRatingStars, isOpenNow } from "../formhelper"
import { FaRegStar } from "react-icons/fa6"
import Notiz from "../components/Notiz"
import { IoInformationCircleOutline } from "react-icons/io5"

const Klinik: React.FC = () => {
	const [loadedDetails, setLoadedDetails] = useState<any>(null)
	const [loadedLeistungen, setLoadedLeistungen] = useState<any>(null)
	const [showLoadingError, setShowLoadingError] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(true)

	const { klinikId } = useParams()

	const sliderSettings = {
		arrows: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		centerMode: true,
		variableWidth: true,
	}

	useEffect(() => {
		axios
			.post((process.env.NODE_ENV === "development" ? "" : "/api") + "/getDetails.php", { klinikname: klinikId })
			.then(resp => {
				if (resp.data.status === "success") {
					setLoadedDetails(resp.data.data)
					setShowLoadingError(false)
				} else {
					setShowLoadingError(true)
				}
				setIsLoading(false)
			})
			.catch(err => {})
		axios
			.get("/api/leistungen/" + klinikId + ".json")
			.then(resp => {
				if (resp.data && resp.data.length > 0) {
					setLoadedLeistungen(resp.data)
				}
			})
			.catch(err => {})
	}, [])

	return (
		<>
			<Link to={"/"}>
				<img src={process.env.PUBLIC_URL + "/images/klinikhero.jpg"} alt="Kur Klinik Suche" className=" pt-0 px-0 w-full" />
			</Link>
			<div className="px-6 ">
				{isLoading && (
					<div className="max-w-[1000px] mx-auto">
						<h1 className="text-center">{decodeAndFormatClinicName(klinikId)}</h1>

						<div className="py-20 text-center">
							<Spinner />
						</div>
					</div>
				)}
				{!isLoading && showLoadingError && (
					<div className="max-w-[1000px] mx-auto">
						<h1 className="text-center">{decodeAndFormatClinicName(klinikId)}</h1>
						<Alert className="my-20">Entschuldigung, hierzu konnten wir leider keine Daten finden.</Alert>
					</div>
				)}

				{loadedDetails && (
					<>
						<h1 className="text-center max-w-[1000px] mx-auto">{loadedDetails.name}</h1>
						{loadedDetails.rating && (
							<div className="text-yellow grid grid-cols-6 gap-0 max-w-[160px] mx-auto items-center">
								{getRatingStars(loadedDetails.rating)}
								<div className="text-black pl-1">{loadedDetails.rating.toString().replace(".", ",")}/5</div>
							</div>
						)}
						{loadedDetails.opening_hours && (
							<div className="text-center pt-3">
								{isOpenNow(loadedDetails.opening_hours) ? (
									<span className="bg-[#014737] text-[#84e1bc] text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">gerade geöffnet</span>
								) : (
									<span className="bg-red text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">gerade geschlossen</span>
								)}
							</div>
						)}

						<div className="grid grid-cols-2 gap-6 items-start py-12 mx-auto max-w-[1000px]">
							<div className="grid grid-cols-1 gap-6">
								{loadedDetails.formatted_address && (
									<div>
										<h3>Adresse</h3>
										<p>{loadedDetails.formatted_address}</p>
									</div>
								)}
								{loadedDetails.formatted_phone_number && (
									<div>
										<h3>Telefonummer</h3>
										<p>{loadedDetails.formatted_phone_number}</p>
									</div>
								)}
								{loadedDetails.website && (
									<div>
										<h3>Website</h3>
										<div>
											<a href={loadedDetails.website} target="_blank" className="underline">
												{getDomainFromUrl(loadedDetails.website)}
											</a>
										</div>
									</div>
								)}
								{loadedDetails.opening_hours && (
									<div>
										<h3>Öffnungszeigen</h3>
										<ul>{formatOpeningHours(loadedDetails.opening_hours)}</ul>
									</div>
								)}
							</div>
							<div>
								<div className="text-center my-6">
									<Link to={"/"} className="btn ">
										<span>in Favoriten speichern</span>
										<FaRegStar className="w-6 h-6 inline-block relative -top-[2px] ml-2 text-black" />
									</Link>
								</div>
								<div className="px-4 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]  rounded-[32px]  p-7 bg-white">
									<h3>Platz für deine Notizen</h3>
									<Notiz id={klinikId} />
									<small className="grid grid-cols-[30px_1fr] items-center opacity-60">
										<IoInformationCircleOutline className="w-6 h-6 " /> Die Daten werden in Browser gespeichert, ein Registrierung ist nicht erforderlich.
									</small>
								</div>
							</div>
						</div>

						{loadedLeistungen && (
							<ul className="grid grid-cols-1 gap-4 pb-12 max-w-[1000px] mx-auto">
								{loadedLeistungen.map((leistung, index) => (
									<li key={"leis" + index} className="relative pl-12 text-lg">
										<CiBookmarkCheck className="w-10 h-10 absolute left-0 -top-1 text-green" />
										{leistung}
									</li>
								))}
							</ul>
						)}

						{loadedDetails.photos && (
							<div className="-mx-6 pb-12">
								<Slider {...sliderSettings}>
									{loadedDetails.photos.map(pic => (
										<div className="relative px-2">
											<img
												src={(process.env.NODE_ENV === "development" ? "" : "/api") + "/getPhoto.php?photo_reference=" + pic.photo_reference}
												width={pic.width}
												height={pic.height}
												className=" w-[28vw] h-[28vw] object-cover"
											/>
											<div className="absolute bottom-0 right-3 text-xs" dangerouslySetInnerHTML={{ __html: pic.html_attributions }} />
										</div>
									))}
								</Slider>
							</div>
						)}
						{loadedDetails.reviews && (
							<div className="pb-12 max-w-[700px] mx-auto">
								<h3>Bewertungen </h3>
								<ul className="grid grid-cols-1 gap-1">
									{loadedDetails.reviews.map(review => (
										<li className="p-6 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]  rounded-[32px]   bg-white">
											<span className="float-right">{review.relative_time_description}</span>
											<div className="pb-2 text-yellow grid grid-cols-5 gap-0 max-w-[130px] ">{getRatingStars(review.rating)}</div>
											<p className="text-base">{review.text}</p>
										</li>
									))}
								</ul>
							</div>
						)}
					</>
				)}
			</div>

			<Footer />
		</>
	)
}
export default Klinik
