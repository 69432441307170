import React from "react"
import { Link } from "react-router-dom"
import Footer from "../components/Footer"

function Imprint() {
	return (
		<div>
			<Link to={"/"}>
				<img src={process.env.PUBLIC_URL + "/images/klinikhero.jpg"} alt="Kur Klinik Suche" className=" pt-0 px-0 w-full" />
			</Link>

			<div className="contentPage max-w-[700px] mx-auto px-4 pb-12">
				<h1>Impressum</h1>
				<p>
					Angaben gemäß § 5 TMG:
					<br />
					<br />
				</p>
				<p>
					Björn Rühle
					<br />
					Maurepasstr. 99H
					<br />
					24558 Henstedt-Ulzburg
					<br />
				</p>
				<br />
				<h2>Kontakt:</h2>
				<p>E-Mail:moin[ät]webstanding.de</p>
				<p />
				<br />
				<h2>Haftungsausschluss:</h2>
				<p>
					<b>Haftung für Inhalte</b>
				</p>
				<p>
					Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach § 8 bis 10 TMG sind wir als
					Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
					Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
					diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
					werden wir diese Inhalte umgehend entfernen.
				</p>
				<p>
					<b>Haftung für Links</b>
				</p>
				<p>
					Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
					übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
					der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
					verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
					umgehend entfernen.
				</p>
				<p>
					<b>Urheberrecht</b>
				</p>
				<p>
					Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und
					jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser
					Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
					Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um
					einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
				</p>
				<p />
			</div>
			<Footer />
		</div>
	)
}

export default Imprint
