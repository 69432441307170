import React from "react"
import { Link } from "react-router-dom"
import Footer from "../components/Footer"
import Map, { Marker } from "react-map-gl"
import { IoPinSharp } from "react-icons/io5"
import SearchForm from "../components/SearchForm"

function Search() {
	return (
		<>
			<Link to={"/"}>
				<img src={process.env.PUBLIC_URL + "/images/klinikhero.jpg"} alt="Kur Klinik Suche" className=" pt-0 px-0 w-full" />
			</Link>
			<h1 className="px-4 text-center">Kliniken suchen</h1>

			<SearchForm />

			<Footer />
		</>
	)
}

export default Search
