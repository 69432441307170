import React from "react"
import { Link } from "react-router-dom"
import Footer from "../components/Footer"

const Home: React.FC = () => {
	const scrollToSelector = selector => {
		setTimeout(() => {
			let errField = document.getElementsByClassName(selector)
			if (errField.length > 0) {
				const firstErrorField: any = errField[0]
				window.scrollTo({
					top: window.pageYOffset + firstErrorField.getBoundingClientRect().top - 200,
					left: 0,
					behavior: "smooth",
					// top: firstErrorField.offsetTop, left: 0, behavior: 'smooth'
				})
			}
		}, 50)
	}

	return (
		<>
			<Link to={"/"}>
				<img src={process.env.PUBLIC_URL + "/images/klinikhero.jpg"} alt="Kur Klinik Suche" className=" pt-0 px-0 w-full" />
			</Link>

			<h1 className="h1">Kur- und Rehakliniken leichter finden</h1>
			<p>kur..de unterstützt dich bei der suche nach deinem Reha oder Kur Platz. </p>

			<div className="relative z-0 pb-12">
				<div className=" sm:px-4 pb-6 md:pb-6 pt-[8%] md:-my-1  md:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]  md:rounded-[32px] md:max-w-[694px] mx-auto grid grid-cols-1 sm:grid-cols-2 gap-5 md:gap-6 md:p-7 bg-grayLight">
					<button
						className="btn min-w-[80%] sm:w-full"
						onClick={e => {
							e.preventDefault()
							scrollToSelector("js-sogehts")
						}}
					>
						zum Ratgeber
					</button>
					<Link to={"/suchen"} className="btn-invert whitespace-nowrap min-w-[80%] sm:w-full">
						Kliniken suchen
					</Link>
				</div>
			</div>

			<Footer />
		</>
	)
}
export default Home
