interface AlertProps {
	children?: any
	className?: any
}

const Alert: React.FC<AlertProps> = ({ children, className }) => {
	return <section className={"bg-red text-center text-white p-2  max-w-[750px] mx-auto " + className}>{children}</section>
}

export default Alert
