const Reducer = (state, action) => {
	switch (action.type) {
		case "SET_JARS":
			let newJars = state.jars
			newJars[action.index] = action.data
			return {
				...state,
				jars: newJars,
			}

		case "SET_UPDATE_STATE":
			return {
				...state,
				[action.key]: action.value,
			}

		case "SET_STATE_STRAPI":
			return {
				...state,
				strapi: {
					...state.strapi,
					[action.key]: action.value,
				},
			}

		default:
			return state
	}
}

export default Reducer
